import React, { Fragment } from "react";
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'



const LatQuotesIntro = () => {

    return (
    <Fragment>
       <Helmet>
 
        <meta name="description" content="Latin quotes on different topics and popular Latin expressions, alphabetized and broken down into pages. A very comprehensive collection, compiled by someone who actually understands Latin." />

      </Helmet>

     <div>
     <div align="center">
    <p> Many Latin expressions are used in books and in everyday English speech, and you will certainly find most those phrases listed and translated on my site. 
However, the existing body of Latin literature, both Classical and Medieval is extremely vast. There are many undiscovered gems of wit and wisdom, so I encourage you to study Latin :)
The source for a quote is sometimes indcated, but one must remember 
that Latin quotations are often used as if they were anonymous
 maxims of universal wisdom. Unlike the collections of quotes that you will find on some other websites, 
these lines have been actually looked at more than once by someone who knows Latin. 
I am sure, however, that some typos have not been fixed, so do not assume that anything you find here is safe and good enough to be tattooed on your person! Always ask a Latinist before using a Latin quote for any permanent purpose.</p>
<p>This site also hosts a plethora of imporant resources  about Latin, as well as some other languages. Most notably, there a list of <Link to ="../../LatinMaxims/LegalQuotes_a"> legal Latin maxims</Link>. 

 Elsewhere on this site there are Latin phrases used in 
 medicine, heraldry, jewelry engravings, 
 sundials and <a href="../../latinlovepoems">Latin love poems</a>. 
 There is even a <Link to="../../latinmottogenerator">motto generator</Link> that 
 lets you create Latin phrases without knowing Latin!</p>


<p><b><Link  activeClassName ='selected' to="../LatQuotes_a">A</Link> | <Link activeClassName ='selected' to="../LatQuotes_b">B</Link> | <Link activeClassName ='selected' to="../LatQuotes_c">C</Link> | <Link activeClassName ='selected' to="../LatQuotes_d">D</Link> | <Link activeClassName ='selected' to="../LatQuotes_e">E</Link> | <Link activeClassName ='selected' to="../LatQuotes_f">F</Link> | <Link activeClassName ='selected' to="../LatQuotes_g">G</Link> | <Link activeClassName ='selected' to="../LatQuotes_h">H</Link> | <Link activeClassName ='selected' to="../LatQuotes_i">I</Link> | <Link activeClassName ='selected' to="../LatQuotes_j">J</Link> | <Link activeClassName ='selected' to="../LatQuotes_l">L</Link> | <Link activeClassName ='selected' to="../LatQuotes_m">M</Link> | <Link activeClassName ='selected' to="../LatQuotes_n">N</Link> | <Link activeClassName ='selected' to="../LatQuotes_o">O</Link> | <Link activeClassName ='selected' to="../LatQuotes_p">P</Link> | <Link activeClassName ='selected' to="../LatQuotes_q">Q</Link> | <Link activeClassName ='selected' to="../LatQuotes_r">R</Link> | <Link activeClassName ='selected' to="../LatQuotes_s">S</Link> | <Link activeClassName ='selected' to="../LatQuotes_t">T</Link> | <Link activeClassName ='selected' to="../LatQuotes_u">U</Link> | <Link activeClassName ='selected' to="../LatQuotes_v">V</Link></b></p>


</div>

     </div>
     </Fragment>
    )
  }
  
  export default LatQuotesIntro
  