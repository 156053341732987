import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_a = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - A'/>

     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>A</h2>
    <LatQuotesIntro />


<p><b>A bene placito</b> - At one's pleasure</p>
<p><b>A bove majori discit arare minor</b> - The young ox learns to plough from the older</p>
<p><b>A capite ad calcem</b> - From head to heel</p>
<p><b>A cappella</b> - Vocals only</p>
<p><b>A contrario</b> - From a contrary position</p>
<p><b>A cruce salus</b> - From the cross comes salvation</p>
<p><b>A Deo et Rege</b> - From God and the King</p>
<p><b>A fonte puro pura defluit aqua</b> - From a clear spring clear water flows</p>
<p><b>A fortiori</b> - With yet stronger reason</p>
<p><b>A fronte praecipitium a tergo lupi</b> - A precipice in front, wolves behind (between a rock and a hard place)</p>
<p><b>A latere</b> - From the side (of a pope's envoy)</p>
<p><b>A mari usque ad mare</b> - From sea to sea (Motto of Canada)</p>
<p><b>A mensa et thoro</b> - From board and bed (legal separation)</p>
<p><b>A pedibus usque ad caput</b> - From feet to head</p>
<p><b>A posse ad esse</b> - From possibility to actuality</p>
<p><b>A posteriori</b> - From what comes after. Inductive reasoning based on observation, as opposed to deductive, or a priori</p>
<p><b>A priori</b> - From what comes before</p>
<p><b>A re decedunt</b> - They wander from the point</p>
<p><b>A teneris unguiculis</b> - From tender little nails, from the earliest childhood.</p>
<p><b>A verbis ad verbera</b> - From words to blows</p>
<p><b>A verbis legis non est recedendum</b> - There must be no departure from the words of the law</p>
<p><b>A vinculo matrimonii</b> - From the bounds of matrimony</p>
<p><b>Ab absurdo</b> - From the absurd (establishing the validity of your argument by pointing out the absurdity of your opponent's position)</p>
<p><b>Ab actu ad posse valet illatio</b> - From what has happened we may infer what will happen</p>
<p><b>Ab aeterno</b> - From the beginning of time</p>
<p><b>Ab alio spectes alteri quod feceris</b> - As you do to another, expect another to do to you</p>
<p><b>Ab amicis honesta petamus</b> - We must ask what is proper from our friends</p>
<p><b>Ab asino lanam</b> - Wool from an ass, blood from a stone impossible</p>
<p><b>Ab hinc</b> - From here on</p>
<p><b>Ab honesto virum bonum nihil deterret</b> - Nothing deters a good man from the performance of his duties</p>
<p><b>Ab imo pectore</b> - From the bottom of the chest. (from the heart) (Julius Caesar)</p>
<p><b>Ab inconvenienti</b> - From the inconvenience</p>
<p><b>Ab incunabulis</b> - From the cradle</p>
<p><b>Ab initio</b> - From the beginning</p>
<p><b>Ab intestato</b> - Having made no will</p>
<p><b>Ab origine</b> - From the origin</p>
<p><b>Ab ovo usque ad mala</b> - From the egg right to the apples (From start to finish) (Horace)</p>
<p><b>Ab ovo</b> - From the egg</p>
<p><b>Ab urbe condita</b> - From the foundation of the city.</p>
<p><b>Ab/Ex uno disce omnes</b> - From one person, learn all people</p>
<p><b>Abeunt studia in mores</b> - Pursuits become habits</p>
<p><b>Abi in pace</b> - Depart in peace</p>
<p><b>Abiit nemine salutato</b> - He went away without bidding anyone farewell</p>
<p><b>Abiit, excessit, evasit, erupit</b> - He has left, absconded, escaped and disappeared</p>
<p><b>Abite nummi, ego vos mergam, ne mergar a vobis</b> - Away with you, money, I will sink you that I may not be sunk by you</p>
<p><b>Abnormis sapiens</b> - Wise without instruction (Horace)</p>
<p><b>Absente reo</b> - In absence of the defendant</p>
<p><b>Absit invidia</b> - No offence intended</p>
<p><b>Absit omen</b> - May the omen be absent. (may this not be an omen)</p>
<p><b>Absque argento omnia vana</b> - Without money all is in vain</p>
<p><b>Absque sudore et labore nullum opus perfectum est</b> - Without sweat and toil no work is made perfect</p>
<p><b>Abundat dulcibus vitiis</b> - He abounds with alluring fruits (Quintilian)</p>
<p><b>Abusus non tollit usum</b> - Wrong use does not preclude proper use</p>
<p><b>Abyssus abyssum invocat</b> - Hell calls hell; one mistep leads to another</p>
<p><b>Accede ad ignem hunc, jam calesces plus satis</b> - Approach this fire, and you will soon be too warm (Terrence)</p>
<p><b>Accensa domo proximi, tua quoque periclitatur</b> - When the house of your neighbour is in flames, your own is in danger</p>
<p><b>Acceptissima semper // munera sunt, auctor quae pretiosa facit</b> - Those gifts are always the most acceptable which our love for the donor makes precious (Ovid)</p>
<p><b>Accidit in puncto, et toto contingit in anno</b> - It happens in an instant and occurs throughout the year</p>
<p><b>Accidit in puncto, quod non contingit in anno</b> - That may happen in a moment, which does not occur in a whole year</p>
<p><b>Accipe, sume, cape, sunt verba placentia papae</b> - Take, have, and keep, are pleasant words from a pope</p>
<p><b>Accipere quam facere praestat injuriam</b> - It is better to suffer an injustice than to do an injustice</p>
<p><b>Accipio revocamen</b> - I accept the recall (Ovid)</p>
<p><b>Acclinis falsis animus meliora recusat</b> - The mind intent upon false appearances refuses to admit better things (Horace)</p>
<p><b>Acer et vehemens bonus orator</b> - A good orator is pointed and forcible (Cicero)</p>
<p><b>Acerrima proximorum odia</b> - The hatred of those most nearly connected is the bitterest of all (Tacit)</p>
<p><b>Acerrimus ex omnibus nostris sensibus est sensus videndi</b> - The keenest of all our senses is the sense of sight (Cicero)</p>
<p><b>Acribus initiis, incurioso fine</b> - Zealous at the commencement, careless towards the conclusion (Tacit)</p>
<p><b>Acriora orexim excitant embammata</b> - Savory seasonings stimulate the appetite</p>
<p><b>Acta est fabula, plaudite!</b> - The play is over, applaud! (Said to have been emperor Augustus' last words)</p>
<p><b>Acta sanctorum</b> - Deeds of the saints</p>
<p><b>Ad absurdum</b> - To the point of absurdity</p>
<p><b>Ad acta</b> - To archives. Not actual any more</p>
<p><b>Ad alta</b> - To the summit</p>
<p><b>Ad astra per aspera</b> - To the stars through difficulty</p>
<p><b>Ad astra</b> - To the stars</p>
<p><b>Ad augusta per angusta</b> - To high places by narrow roads</p>
<p><b>Ad calamitatem quilibet rumor valet</b> - Every rumor is believed when directed against the unfortunate (Syrus)</p>
<p><b>Ad Calendas Graecas</b> - At the Greek Calends (meaning ?never?)</p>
<p><b>Ad captandum vulgus</b> - To appeal to the crowd</p>
<p><b>Ad clerum</b> - To the clergy</p>
<p><b>Ad connectendas amicitias, tenacissimum vinculum est morum similitudo</b> - For cementing friendship, resemblance of manners is the strongest tie (Pliny the Younger)</p>
<p><b>Ad consilium ne accesseris, antequam voceris</b> - Do not go to the council-chamber before you are summoned</p>
<p><b>Ad eundem gradum</b> - To the same level</p>
<p><b>Ad eundem</b> - Of admission to the same degree at a different university</p>
<p><b>Ad eundum quo nemo ante iit</b> - To boldly go where no man has gone before</p>
<p><b>Ad fontes</b> - To the sources (motto of Renaissance Humanism)</p>
<p><b>Ad fundum</b> - To the bottom / To the end (said during a generic toast, like bottoms up!)</p>
<p><b>Ad hoc</b> - For a particular purpose. (improvised, made up in an instant)</p>
<p><b>Ad hominem</b> - Appealing to a person's physical and emotional urges, rather than her or his intellect</p>
<p><b>Ad honorem</b> - In honour. Honour not baring any material advantage</p>
<p><b>Ad idem</b> - Of the same mind</p>
<p><b>Ad infinitum</b> - To infinity without end</p>
<p><b>Ad interim</b> - For the meantime</p>
<p><b>Ad libitum ('ad lib')</b> - At one's pleasure</p>
<p><b>Ad libitur</b> - As desired</p>
<p><b>Ad limina apostolorum</b> - To the thresholds of the Apostles</p>
<p><b>Ad litem</b> - For a lawsuit or action</p>
<p><b>Ad locum</b> - At the place</p>
<p><b>Ad lucem</b> - Towards the light (motto of the University of Lisbon)</p>
<p><b>Ad maiorem dei gloriam (AMDG)</b> - For the greater glory of God</p>
<p><b>Ad mala quisque animum referat sua</b> - Let each person recall to mind his own mishaps (Ovid)</p>
<p><b>Ad mensuram aquam bibit</b> - He drinks water by measure</p>
<p><b>Ad minora me demittere no recusabo</b> - I will not refuse to descent to the most minute details (Quintilian)</p>
<p><b>Ad mores natura recurrit damnatos, fixa et mutari nescia</b> - Human nature ever reverts to its depraved courses, fixed and immutable (Juvenal)</p>
<p><b>Ad multos annos</b> - To many years!, i.e. Many happy returns!</p>
<p><b>Ad nauseam</b> - To the point of making one sick</p>
<p><b>Ad nomen vultus sustulit illa suos</b> - On hearing her name she raised her eyes (Ovid)</p>
<p><b>Ad nullum consurgit opus, cum corpore languet</b> - The mind cannot grapple with any task when the body is languid (Gallus)</p>
<p><b>Ad omnem libidinem projectus homo</b> - A man disposed to every species of dissipation</p>
<p><b>Ad perditam securim manubrium adjicere</b> - To throw the helve after the lost hatchet. To give way to dispair.</p>
<p><b>Ad perniciem solet agi sinceritas</b> - Sinceity is frequently impelled to its own destruction (Phaedrus)</p>
<p><b>Ad perpetuam rei memoriam</b> - For the perpetual remembrance of the thing</p>
<p><b>Ad populum phaleras, ego te intus et in cute novi</b> - Display your trappings to the vulgar, I know you inside and out.</p>
<p><b>Ad praesens ova cras pullis sunt meliora</b> - Eggs today are better than chickens tomorrow (a bird in the hand is worth two in the bush)</p>
<p><b>Ad referendum</b> - Subject to reference</p>
<p><b>Ad rem</b> - To the point</p>
<p><b>Ad respondendum quaestioni</b> - To answer the question. </p>
<p><b>Ad suum quemque aequum est quaestum esse callidum</b> - It is only right that every one should be alive to his own advantage (Plautus)</p>
<p><b>Ad tristem partem strenua suspicio</b> - The minds of men who have been unfortunate are prone to suspicion (Syrus)</p>
<p><b>Ad turpia virum bonum nulla spes invitat</b> - No expectation can allure a good man to the commission of evil (Seneca)</p>
<p><b>Ad unum corpus humanum supplicia plura quam membra</b> - One human body is liable to more pains than the members of which it is composed (St. Cyprian)</p>
<p><b>Ad utrumque paratus</b> - Prepared for either alternative</p>
<p><b>Ad valorem</b> - By the value, e.g. Ad valorem tax</p>
<p><b>Ad vitam aeternam</b> - For all time</p>
<p><b>Ad vitam paramus</b> - We are preparing for life</p>
<p><b>Ad vitam</b> - For life</p>
<p><b>Adaequarunt judices</b> - The judges were equally devided</p>
<p><b>Adde parum parvo, magnus acervus erit</b> - Add a little to a little, and there will be a great heap (adaptation from Ovid)</p>
<p><b>Adde, quod injustum rigido jus dicitur ense; dantur et in medio vulnera saepe foro.</b> - Besides, iniquitous retaliation is dealt with the cruel sward, and wounds are often inflicted in the midst of the court of justice (Ovid)</p>
<p><b>Addendum</b> - A thing to be added</p>
<p><b>Adeo in teneris consuescere multum est</b> - Of such importance is to be well trained in youth (Virgil)</p>
<p><b>Adeo ne hominem immutare ex amore, ut non cognoscas eundem esse</b> - Is it possible that a man can be so changed by love, that you could not recognize him to be the same? (Terrence)</p>
<p><b>Adeste, fideles!</b> - Be present, faithful ones!</p>
<p><b>Adeste, si quid mihi restat agendum!</b> - Be at hand, if there is anything more for me to do. (Emperor Severus? last words, according to F. Bacon)!</p>
<p><b>Adhibenda est in jocando moderatio</b> - Moderation should be used in joking (Cicero)</p>
<p><b>Adhibenda est munditia, non odiosa, neque exquisita nimis, tantum quae fugiat agrestem ac inhumanam negligentiam</b> - We should exhibit a certain degree of neatness, not too exquisite or affected, and equally remote from rustic and unbecoming carelessness (Cicero)</p>
<p><b>Adhuc sub judice lis est</b> - The point is still in dispute before the judge (Horace)</p>
<p><b>Aditus est ipsi ad omnes facilis et pervius</b> - He has free and ready access to everyone.</p>
<p><b>Adolescentem verecundum esse decet</b> - A young man ought to be modest (Plautus)</p>
<p><b>Adornare verbis benefacta</b> - To enhance the value of a favor by kind expressions (Pliny the Younger)</p>
<p><b>Adscriptus glebae</b> - Belonging to the soil, attached to the soul (of peasants)</p>
<p><b>Adsit regula, peccatis quae poenas irroget aequas</b> - Let a law be made which inflicts punishment commensurate with the crime (Horace)</p>
<p><b>Adsum!</b> - Here! present!</p>
<p><b>Adversus incendia excubias nocturnas vigilesque commentus est</b> - Against the dangers of fires, he (Augustus) conceived of the idea of night guards and watchmen</p>
<p><b>Adversus solem ne loquitor</b> - Don't speak against the sun (don't waste your time arguing the obvious)</p>
<p><b>Aeacus in poenas ingeniosus erit</b> - Aeacus will refine in devising tortures (for you) (Ovid)</p>
<p><b>Aegrescit medendo</b> - The disease worsens with the treatment. The remedy is worse than the disease</p>
<p><b>Aegri somnia vana</b> - A sick man's delusive dreams (Horace)</p>
<p><b>Aegroto, dum anima est, spes esse dicitur</b> - It is said that for a sick man, there is hope as long as there is life (Cicero)</p>
<p><b>Aemulatio aemulationem parit</b> - Emulation begets emulation</p>
<p><b>Aemulus studiorum et laborum</b> - The rival of pursuits and labors (Cicero)</p>
<p><b>Aequa lege necessitas sortitur insignes et imos</b> - Fate, by an impartial law, is allotted both to the conspicuous and the obscure (Horace)</p>
<p><b>Aequa tellus pauperi recluditur, regumque pueris</b> - The impartial earth is opened alike for the pauper and the children of kings (Horace)</p>
<p><b>Aequam memento rebus in arduis servare mentem</b> - Remember when life's path is steep to keep your mind even. (Horace)</p>
<p><b>Aequam memento rebus in arduis servare mentem, non secus in bonis ab insolenti temperatam laetitia</b> - In arduous circumstances remember to preserve calmness of soul, and equally in prosperous moments restrain excessive joy (Horace)</p>
<p><b>Aequitas enim lucet ipsa per se</b> - Equity shines by her own light (Cicero)</p>
<p><b>Aeternum vale</b> - Farewell forever</p>
<p><b>Affidavit</b> - A sworn written statement usable as evidence in court</p>
<p><b>Age quod agis</b> - Do what you do well, pay attention to what you are doing</p>
<p><b>Age. Fac ut gaudeam</b> - Go ahead. Make my day!</p>
<p><b>Agenda</b> - Things to be done</p>
<p><b>Agnus Dei</b> - The Lamb of God</p>
<p><b>Alea iacta est</b> - The die has been cast. (Caesar)</p>
<p><b>Alias</b> - Otherwise</p>
<p><b>Alibi</b> - Elsewhere</p>
<p><b>Aliena nobis, nostra plus aliis placent</b> - Other people's things are more pleasing to us, and ours to other people. (Publilius Syrus)</p>
<p><b>Alis volat propiis</b> - She flies with her own wings (state motto of Oregon)</p>
<p><b>Alma Mater</b> - Nourishing mother. (One's old school or university)</p>
<p><b>Alter ego</b> - Other 'I' or 'Other Self'</p>
<p><b>Alter ipse amicus</b> - A friend is another self</p>
<p><b>Altissima quaeque flumina minimo sono labi</b> - The deepest rivers flow with the least sound. (still waters run deep)</p>
<p><b>Alumnus</b> - Nursling (former student)</p>
<p><b>Amantes sunt amentes</b> - Lovers are lunatics</p>
<p><b>Amantium irae amoris integratio est</b> - The quarrels of lovers are the renewal of love. (Terence)</p>
<p><b>Amare et sapere vix deo conceditur</b> - Even a god finds it hard to love and be wise at the same time</p>
<p><b>Amat victoria curam</b> - Victory favors those who take pains</p>
<p><b>Amicitiae nostrae memoriam spero sempiternam fore</b> - I hope that the memory of our friendship will be everlasting. (Cicero)</p>
<p><b>Amicus certus in re incerta cernitur</b> - A true friend is discerned during an uncertain matter</p>
<p><b>Amicus humani generis</b> - A friend of the human race (philanthropist)</p>
<p><b>Amicus verus est rara avis</b> - A true friend is a rare bird</p>
<p><b>Amor animi arbitrio sumitur, non ponitur</b> - We choose to love, we do not choose to cease loving. (Syrus)</p>
<p><b>Amor caecus est</b> - Love is blind</p>
<p><b>Amor est vitae essentia</b> - Love is the essence of life. (Robert B. Mackay)</p>
<p><b>Amor ordinem nescit</b> - Love does not know order. (St. Jerome)</p>
<p><b>Amor patriae</b> - Love of country</p>
<p><b>Amor platonicus</b> - Platonic love</p>
<p><b>Amor tussisque non celantur</b> - Love, and a cough, are not concealed. (Ovid)</p>
<p><b>Amor vincit omnia</b> - Love conquers all. (from Virgil)</p>
<p><b>Amoto quaeramus seria ludo</b> - Joking aside, let us turn to serious matters. (Horace)</p>
<p><b>An nescis, mi fili, quantilla sapientia mundus regatur?</b> - Don't you know then, my son, how little wisdom rules the world?</p>
<p><b>Anguis in herba</b> - A snake in the grass. A treacherous person. (Vergil)</p>
<p><b>Anicularum lucubrationes</b> - Old wives' tales</p>
<p><b>Animis opibusque parati</b> - Prepared in minds and resources (ready for anything)</p>
<p><b>Animus facit nobilem</b> - The spirit makes (human) noble</p>
<p><b>Anno (an.)</b> - Year</p>
<p><b>Anno domini (AD)</b> - In the year of the Lord</p>
<p><b>Anno hegirae (AH)</b> - In the year of the hegira</p>
<p><b>Anno mundi</b> - In the year of the world</p>
<p><b>Anno regni</b> - In the year of reign</p>
<p><b>Anno urbis conditae (AUC)</b> - From the year of founding of the city (Rome)</p>
<p><b>Annuit coeptis</b> - God has favored us</p>
<p><b>Annus bisextus</b> - Leap year</p>
<p><b>Annus horribilis</b> - A horrible year</p>
<p><b>Annus mirabilis</b> - Year of wonders</p>
<p><b>Ante litteram</b> - Before the letter</p>
<p><b>Ante meridiem (a.m.)</b> - Before midday</p>
<p><b>Ante mortem</b> - Before death</p>
<p><b>Ante prandium (A.p.)</b> - Before a meal</p>
<p><b>Ante</b> - Before</p>
<p><b>Antebellum</b> - Before the war</p>
<p><b>Aqua pura</b> - Pure water</p>
<p><b>Aqua vitae</b> - Water of life</p>
<p><b>Aquila non captat muscas</b> - The eagle doesn't capture flies (don't sweat the small things)</p>
<p><b>Arbiter elegantiae</b> - Judge in matters of taste</p>
<p><b>Arcana imperii</b> - Secrets of the empire</p>
<p><b>Arduum sane munus</b> - A truly arduous task</p>
<p><b>Arguendo</b> - For the sake of argument</p>
<p><b>Argumentum ad hominem</b> - An argument against the man. Directing an argument against an opponent's character rather than the subject at hand</p>
<p><b>Argumentum ad ignorantiam</b> - Arguing from ignorance</p>
<p><b>Armis Exposcere Pacem</b> - They demanded peace by force of arms. (inscription seen on medals)</p>
<p><b>Ars gratia artis</b> - Art for art's sake. (motto of MGM)</p>
<p><b>Ars longa, vita brevis</b> - Art (work) is long, but life is short</p>
<p><b>Ars sine scientia nihil est</b> - Art without science is nothing. (I would also claim that the opposite is true)</p>
<p><b>Artium baccalaureus</b> - Bachelor of Arts (BA)</p>
<p><b>Artium magister</b> - Master of Arts (MA)</p>
<p><b>Aspirat primo Fortuna labori</b> - Fortune smiles upon our first effort. (Virgil)</p>
<p><b>Assiduus usus uni rei deditus et ingenium et artem saepe vincit</b> - Constant practice devoted to one subject often outdues both intelligence and skill. (Cicero)</p>
<p><b>Astra inclinant, non necessitant</b> - The stars incline; they do not determine</p>
<p><b>Astra non mentiuntur, sed astrologi bene mentiuntur de astris</b> - The stars never lie, but the astrologs lie about the stars</p>
<p><b>Audaces fortuna iuvat</b> - Fortune favors the bold. (Virgil)</p>
<p><b>Audere est facere</b> - To dare is to do. (Motto of Tottenham Hotspur)</p>
<p><b>Audi et alteram partem</b> - Hear the other side too</p>
<p><b>Audiatur et altera pars!</b> - Let us hear the opposite side!</p>
<p><b>Audio, video, disco</b> - I hear, I see, I learn</p>
<p><b>Auget largiendo</b> - He increases by giving liberally</p>
<p><b>Aura popularis</b> - The popular breeze. (Cicero)</p>
<p><b>Aurea mediocritas</b> - The golden mean. (an ethical goal; truth and goodness are generally to be found in the middle.) (Horace)</p>
<p><b>Auribus tenere lupum</b> - I hold a wolf by the ears. (I am in a dangerous situation and dare not let go.) (Terence)</p>
<p><b>Aurora australis</b> - The Southern lights</p>
<p><b>Aurora borealis</b> - The Northern lights</p>
<p><b>Aurora Musis amica</b> - Dawn is friend of the muses. (Early bird catches the worm.)</p>
<p><b>Aut Caesar aut nihil</b> - Caesar or nothing i.e., all or nothing</p>
<p><b>Aut disce aut discede</b> - Either learn or leave</p>
<p><b>Aut insanit homo, aut versus facit</b> - The fellow is either mad or he is composing verses. (Horace)</p>
<p><b>Aut viam inveniam aut faciam</b> - I will either find a way or make one</p>
<p><b>Aut vincere aut mori</b> - Either conquer or die</p>
<p><b>Auxilio ab alto</b> - By help from on high</p>
<p><b>Avarus animus nullo satiatur lucro</b> - A greedy mind is satisfied with no (amount of) gain</p>
<p><b>Ave atque vale</b> - Hail and farewell. (Catullus)</p>
<p><b>Ave caesar! Morituri te salutamus</b> - Hail Caesar! We who are about to die salute you. (gladiators before the fight)</p>
<p><b>Ave Maria</b> - Hail Mary</p>
   </Layout>
  )
}

export default LatQuotes_a
